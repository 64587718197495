<script>
import PageHeader from "@/components/page-header";
// import { ApiClientSelectedPlatform } from "@/common/http-common"
import { BredcrumpManagerEncode } from "@/common/bredcrumb-common"
// import { showNotificationFromServer, showNotificationWithText } from "@/common/notification-common"
export default {
  components: {
    PageHeader
  },
  data() {
    return {
      title: this.$t('orders.update'),
    };
  },
  methods: {
  
  },
  mounted() {
    BredcrumpManagerEncode(this, { path: this.$route.path, title: 'orders.update'})
  }
};
</script>
<template>
  <div class="row">
    <div class="col-xl-12">
      <div class="col-xl-12">
        <PageHeader :title="title" />
      </div>
      <div class="row">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <h4 class="card-title">{{ this.$t('orders.update') }}</h4>
              <p class="card-title-desc">
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>